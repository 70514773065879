<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#333">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                {{
                  get_permission.new ? "Criar permissão" : get_permission.nome
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="get_permission._id">
              <v-btn
                color="red"
                @click="excluir_permission_dialog(get_permission)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div
      style="max-height: 85vh; min-height: 84vh; overflow: auto;"
      class="windows-style-content expande-horizontal wrap"
    >
      <v-flex xs12>
        <div class="expande-horizontal wrap py-2 pb-0 fonte">
          <v-flex xs12>
            <v-form ref="form">
              <v-flex class="pa-1" xs12>
                <v-text-field
                  v-model="get_permission.nome"
                  dense
                  flat
                  filled
                  label="Nome da permissão"
                  placeholder="Ex: Diretor"
                  hide-details
                  clearable
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                ></v-text-field>
              </v-flex>
              <v-flex class="pa-1" xs12>
                <v-select
                  v-model="get_permission.rota_inicial"
                  dense
                  flat
                  filled
                  hide-details
                  label="Rota Inicial"
                  placeholder="Ex: Diretor"
                  item-text="nome"
                  item-value="rota"
                  clearable
                  :items="permissions.filter(item => item.rota !== '/no')"
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                ></v-select>
              </v-flex>
              <v-flex class="px-1" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex
                    v-for="permission in permissions"
                    :key="`perm-${permission.nome}`"
                    class="pa-1"
                    xs12
                    md3
                  >
                    <v-list
                      style="border-radius: 12px;"
                      dark
                      height="90"
                      color="#444"
                      dense
                      class="ma-0 pa-0"
                    >
                      <v-list-item class="px-2">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon size="12">mdi-lock-outline</v-icon>
                            {{ permission.nome }}
                          </v-list-item-title>
                          <span class="fonte fonteMini grey--text">{{
                            permission.description
                          }}</span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            small
                            :color="$theme.primary"
                            v-model="
                              get_permission.permission[permission.permission]
                            "
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <div class="expande-horizontal pa-2">
            <v-btn
              dark
              @click="valida_form"
              class="black--text elevation-0"
              rounded
              :color="$theme.primary"
            >
              <span class="fonte"> Salvar </span>
            </v-btn>
          </div>
        </div>
      </v-flex>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      permissions: [
        {
          nome: "Dashboard",
          permission: "dashboard",
          description: "O usuário terá acesso a tela de dashboard.",
          rota: "/dashboard"
        },
        {
          nome: "Vender",
          permission: "vender",
          description:
            "O usuário poderá acessar, abrir e fechar seu próprio caixa.",
          rota: "/atendimento"
        },
        {
          nome: "Vendas",
          permission: "gestor-de-pedidos",
          description: "O usuário poderá dar andamento nas vendas realizadas.",
          rota: "/gestordepedidos"
        },
        {
          nome: "Menu da loja",
          permission: "menu-loja",
          description:
            "O usuário poderá abrir e fechar o catálogo, copiar o link e receber pedidos.",
          rota: "/no"
        },
        {
          nome: "Resumo últimos 30 dias",
          permission: "visualizar-resumo-30-dias",
          description:
            "O usuário poderá visualizar o resumo dos últimos 30 dias.",
          rota: "/no"
        },
        {
          nome: "Garçom",
          permission: "meus-pedidos",
          description: "O usuário poderá dar andamento nas vendas realizadas.",
          rota: "/garcom"
        },
        {
          nome: "Minha Agenda",
          permission: "minha-agenda",
          description:
            "O usuário poderá acompanhar agendamentos feitos em seu nome.",
          rota: "/minha-agenda"
        },
        {
          nome: "Agenda",
          permission: "agenda",
          description:
            "O usuário poderá criar e gerenciar agendamentos para todos os usuários.",
          rota: "/agenda"
        },
        {
          nome: "Lista de compras",
          permission: "lista-de-compras",
          description:
            "O usuário irá visualizar produtos que estejam com o estoque abaixo do estoque mínimo.",
          rota: "/lista-de-compras"
        },
        {
          nome: "Produtos",
          permission: "produtos",
          description:
            "O usuário poderá visualizar, criar, editar e excluir produtos.",
          rota: "/menuonline"
        },
        {
          nome: "Insumos",
          permission: "insumos",
          description:
            "O usuário poderá visualizar, criar, editar e excluir insumos utilizados para compor produtos.",
          rota: "/insumos"
        },
        {
          nome: "Clientes",
          permission: "clientes",
          description:
            "O usuário poderá visualizar, criar, editar e excluir clientes.",
          rota: "/clientes"
        },
        {
          nome: "Colaboradores",
          permission: "colaboradores",
          description:
            "O usuário poderá visualizar, criar, editar e excluir colaboradores.",
          rota: "/funcionarios"
        },
        {
          nome: "Invoices",
          permission: "contas",
          description:
            "O usuário poderá visualizar, criar, editar e excluir contas a pagar e a receber.",
          rota: "/invoices"
        },
        {
          nome: "Áreas de Entrega",
          permission: "configurar-delivery",
          description:
            "O usuário poderá visualizar, criar, editar e excluir contas áreas de entrega.",
          rota: "/delivery"
        },
        {
          nome: "Contabilidade de Caixas",
          rota: "/acompanhamento-de-caixa",
          permission: "contabilidade-de-caixas",
          description:
            "O usuário poderá visualizar a aprovar a contabilidade dos registros dos caixas."
        },
        {
          nome: "Relatório Contabilidade",
          permission: "relatorio-de-contabilidade",
          description:
            "O usuário poderá visualizar relatórios com base nas contas aprovadas dos caixas.",
          rota: "/caixas"
        },
        {
          nome: "Permissões",
          permission: "permissoes",
          description:
            "O usuário poderá visualizar, criar, editar e excluir permissões, cuidado.",
          rota: "/permissoes"
        },
        {
          nome: "Configurações",
          permission: "configuracoes",
          description:
            "O usuário poderá visualizar e editar os dados da empresa.",
          rota: "/configurar-loja"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_permission", "getLoggedUser"])
  },
  methods: {
    ...mapActions([
      "criar_permission",
      "atualizar_permission",
      "fecha_modal_view_permission"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_permission._id
          ? this.atualizar_permission()
          : this.criar_permission();
      }
    },
    backToList() {
      this.$store.commit("set_permission", {});
      this.$forceUpdate();
    }
  }
};
</script>
